import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '@/api'
export const fetchListVerificationRequests = createAsyncThunk(
  'listVerificationRequests/fetchListVerificationRequests',
  async(params) => {
    const response = await api.listVerificationRequests.index(params)
    return response.data
  }
)

export const fetchListVerificationRequest = createAsyncThunk(
  'listVerificationRequests/fetchListVerificationRequest',
  async(id) => {
    const response = await api.listVerificationRequests.show(id)
    return response.data
  }
)

const initialState = {
  listVerificationRequests: {
    data: [],
    page: 1,
    perPage: 10,
    totalPages: 0
  },
  currentListVerificationRequest: null,
  fetchingCurrentListVerificationRequest: false,
  loading: true,
  error: null
}

export const listVerificationRequestsSlice = createSlice({
  name: 'listVerificationRequests',
  initialState,
  reducers: {
    setListVerificationRequest: (state, action) => {
      state.currentListVerificationRequest = action.payload
    }
  },
  extraReducers: {
    [fetchListVerificationRequests.pending]: state => {
      state.loading = true
    },
    [fetchListVerificationRequests.fulfilled]: (state, action) => {
      state.listVerificationRequests = action.payload
      state.loading = false
    },
    [fetchListVerificationRequests.rejected]: (state, action) => {
      state.listVerificationRequests = []
      state.loading = false
    },
    [fetchListVerificationRequest.pending]: state => {
      state.fetchingCurrentListVerificationRequest = true
    },
    [fetchListVerificationRequest.fulfilled]: (state, action) => {
      state.currentListVerificationRequest = action.payload
      state.fetchingCurrentListVerificationRequest = false
    },
    [fetchListVerificationRequest.rejected]: (state, action) => {
      state.currentListVerificationRequest = null
      state.fetchingCurrentListVerificationRequest = false
    }
  }
})


export const {setListVerificationRequest} = listVerificationRequestsSlice.actions

export default listVerificationRequestsSlice.reducer
