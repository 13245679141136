const openChat = function () {
  if (typeof $chatwoot !== 'undefined' && !$chatwoot.isOpen) {
    $chatwoot?.toggle()
  }
}

const closeChat = function () {
  if (typeof $chatwoot !== 'undefined' && $chatwoot.isOpen) {
    $chatwoot?.toggle()
  }
}

const toggleChat = function () {
  if (typeof $chatwoot !== 'undefined') {
    $chatwoot?.toggle()
  }
}

export { openChat, closeChat, toggleChat }
